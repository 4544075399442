import {
  GET_USER_IP,
  USER_IP_ERROR,
  GET_BANNED_USER_IP,
  ERROR_BANNED_USER_IP,
} from "../types";

const initialState = {
  userIP: "",
  bannedIP: [],
  error: "",
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_IP:
      return {
        ...state,
        userIP: action.payload,
        loading: false,
      };
    case USER_IP_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: true,
      };
    case GET_BANNED_USER_IP:
      return {
        ...state,
        bannedIP: action.payload.message,
        loading: false,
      };
    case ERROR_BANNED_USER_IP:
      return {
        ...state,
        bannedIP: action.payload.message,
        error: action.payload,
        loading: true,
      };
    default:
      return state;
  }
}
