/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./style.scss";
import { ImCross } from "react-icons/im";
import { getSearch, reset } from "../../store/actions/searchAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const search = () => {
  const deflang = localStorage.getItem("def_lang")
    ? JSON.parse(localStorage.getItem("def_lang"))
    : localStorage.getItem("i18nextLng");
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");

  const searchList = useSelector((state) => state.search);
  const { i18n } = useTranslation();
  const { loading, error, search } = searchList;

  console.log(process.env.NODE_ENV);

  const searchProduct = (event) => {
    setSearchValue(event.target.value);
    if (event.target.value.length >= 5) {
      dispatch(getSearch(event.target.value));
    }
  };

  return (
    <>
      <div id="searchbox">
        <div className="wrap">
          <div className="search">
            <input
              type="text"
              className="searchTerm"
              placeholder={
                deflang == "en"
                  ? i18n.store.data.en.translation.search
                  : deflang == "ar"
                  ? i18n.store.data.ar.translation.search
                  : i18n.store.data.he.translation.search
              }
              onChange={searchProduct}
              value={searchValue}
            />
            <button type="submit" className="searchButton">
              <ImCross
                onClick={() => {
                  setSearchValue("");
                  dispatch(reset());
                }}
              />
            </button>
          </div>
        </div>
      </div>
      <div className="searchres">
        {loading || searchValue == "" ? (
          ""
        ) : (
          <Link
            to={{
              pathname: `/product/${search.id}`,
              state: search,
            }}
          >
            {search.product_name}
          </Link>
        )}
      </div>
    </>
  );
};

export default search;
