/* eslint-disable no-undef */
import {
  GET_SETTINGS,
  SETTINGS_ERROR,
  GET_PHONE,
  PHONE_ERROR,
  GET_LANGUAGE,
  LANGUAGE_ERROR,
  SHOW_LANGUAGE_ERROR,
  SHOW_LANGUAGE,
} from "../types";
import axios from "axios";

const apiUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PRO_HOMEPAGE
    : process.env.REACT_APP_DEV_HOMEPAGE;

export const getSettingsByName = (name) => async (dispatch) => {
  try {
    // eslint-disable-next-line no-undef
    const res = await axios.get(`${apiUrl}api/settings/?name=${name}`);
    if (res.data.setting_name == "logo") {
      const backendUrl =
        process.env.NODE_ENV === "production"
          ? process.env.REACT_APP_PRO_HOMEPAGE
          : process.env.REACT_APP_DEV_HOMEPAGE;
      localStorage.setItem(
        "logo",
        JSON.stringify(backendUrl + res.data.setting_value)
      );
    }
    dispatch({
      type: GET_SETTINGS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SETTINGS_ERROR,
      payload: error,
    });
  }
};

export const getPhone = (name) => async (dispatch) => {
  try {
    // eslint-disable-next-line no-undef
    const res = await axios.get(`${apiUrl}api/settings/?name=${name}`);
    dispatch({
      type: GET_PHONE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: PHONE_ERROR,
      payload: error,
    });
  }
};
export const getLanguage = () => async (dispatch) => {
  try {
    // eslint-disable-next-line no-undef
    const res = await axios.get(`${apiUrl}api/language/`);
    dispatch({
      type: GET_LANGUAGE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: LANGUAGE_ERROR,
      payload: error,
    });
  }
};
export const showLanguage = (name) => async (dispatch) => {
  try {
    // eslint-disable-next-line no-undef
    const res = await axios.get(`${apiUrl}api/settings/?name=${name}`);
    dispatch({
      type: SHOW_LANGUAGE,
      payload: res.data,
    });
    localStorage.setItem("showLang", JSON.stringify(res.data.setting_value));
  } catch (error) {
    dispatch({
      type: SHOW_LANGUAGE_ERROR,
      payload: error,
    });
  }
};
