import {
  GET_SETTINGS,
  SETTINGS_ERROR,
  GET_PHONE,
  PHONE_ERROR,
  GET_LANGUAGE,
  LANGUAGE_ERROR,
  SHOW_LANGUAGE_ERROR,
  SHOW_LANGUAGE,
} from "../types";

const initialState = {
  settings: {},
  loading: true,
  phone: {},
  langSetting: {},
  showLang: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
        loading: false,
      };
    case GET_PHONE:
      return {
        ...state,
        phone: action.payload,
        loading: false,
      };
    case GET_LANGUAGE:
      return {
        ...state,
        langSetting: action.payload,
        loading: false,
      };
    case SHOW_LANGUAGE:
      return {
        ...state,
        showLang: action.payload,
        loading: false,
      };
    case SETTINGS_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    case PHONE_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    case LANGUAGE_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    case SHOW_LANGUAGE_ERROR:
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
