import { combineReducers } from "redux";
import Categories from "./categoriesReducer";
import Products from "./productReducer";
import Settings from "./settingsReducer";
import Language from "./langReducer";
import Search from "./searchReducer";
import UserIP from "./ipReducer";

export default combineReducers({
  categories: Categories,
  products: Products,
  settings: Settings,
  defLang: Language,
  search: Search,
  userIP: UserIP,
});
