import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import sequenceAction from "redux-sequence-action";

import rootReducer from "./reducers";

const showLangFromLocalStorage = localStorage.getItem("showLang")
  ? JSON.parse(localStorage.getItem("showLang"))
  : null;

const initalState = {
  showLang: showLangFromLocalStorage,
};

const middleware = [thunk, sequenceAction];

const store = createStore(
  rootReducer,
  initalState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
