import {
  GET_CATEGORIES,
  CATEGORIES_ERROR,
  GET_CATEGORIES_BY_ID,
  CATEGORIES_BY_ID_ERROR,
  GET_CATEGORIES_NAME_BY_ID,
  CATEGORIES_NAME_BY_ID_ERROR,
} from "../types";

const initialState = {
  categories: [],
  categoryName: {},
  catNaam: "",
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        loading: false,
      };
    case GET_CATEGORIES_BY_ID:
      return {
        ...state,
        categoryName: action.payload,
      };
    case GET_CATEGORIES_NAME_BY_ID:
      return {
        ...state,
        catNaam: action.payload,
      };
    case CATEGORIES_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    case CATEGORIES_NAME_BY_ID_ERROR:
      return {
        loading: false,
        error: action.payload,
      };
    case CATEGORIES_BY_ID_ERROR:
      return {
        error: action.payload,
      };

    default:
      return state;
  }
}
