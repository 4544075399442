/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import ReactHtmlParser from "react-html-parser";
import { trimString } from "../helper/helper";
import "./style.scss";
//import ModalImage from "react-modal-image";
import { Modal } from "react-bootstrap";
import { TiTimesOutline } from "react-icons/ti";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from "react-alice-carousel";
import { useTranslation } from "react-i18next";

const card = ({ index, key, name, desc, image, price, productsArray }) => {
  //const lang = localStorage.getItem("i18nextLng");
  const deflang = localStorage.getItem("def_lang")
    ? JSON.parse(localStorage.getItem("def_lang"))
    : localStorage.getItem("i18nextLng");
  const [show, setShow] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const { i18n } = useTranslation();

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setActiveIndex(index);
  };

  const responsive = {
    0: { items: 1 },
    1024: { items: 1 },
  };

  const item = productsArray.map((p, i) => (
    <div
      key={p.id}
      data-value={i}
      className="modalcontent"
      style={deflang != "en" ? { direction: "rtl" } : { direction: "ltr" }}
    >
      <div
        className="mcimage"
        style={
          deflang != "en"
            ? { marginLeft: "1%", marginRight: "0" }
            : { direction: "ltr" }
        }
      >
        <img
          src={`${p.image_slug}`}
          alt=""
          style={
            deflang != "en"
              ? {
                  objectFit: "cover",
                }
              : { direction: "ltr" }
          }
        />
      </div>
      <div
        className="productdetail"
        style={
          deflang != "en"
            ? { textAlign: "right", marginLeft: "0", marginRight: "1%" }
            : { direction: "ltr" }
        }
      >
        <div className="title">
          <h5>{p.product_name}</h5>
        </div>
        <div className="price">
          <h5>{p.product_number}</h5>
        </div>
      </div>
    </div>
  ));

  const slidePrev = () =>
    setActiveIndex(activeIndex == 0 ? 0 : activeIndex - 1);
  const slideNext = () =>
    setActiveIndex(
      activeIndex == productsArray.length - 1
        ? productsArray.length - 1
        : activeIndex + 1
    );
  //const onSlideChanged = ({ item }) => setActiveIndex(item);

  return (
    <div id="procard">
      <div className="pro-image" onClick={handleShow}>
        <img src={`${image}`} alt="" />
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        key={key}
        dialogClassName="productmodal"
        style={deflang != "en" ? { direction: "rtl" } : { direction: "ltr" }}
      >
        <Modal.Body>
          <TiTimesOutline
            className="crs"
            style={
              deflang != "en"
                ? {
                    right: "inherit",
                    left: "10px",
                  }
                : { direction: "ltr" }
            }
            onClick={handleClose}
          />
          <AliceCarousel
            mouseTracking
            items={item}
            disableDotsControls
            disableButtonsControls
            animationType="fadeout"
            activeIndex={activeIndex}
          />
          {productsArray.length <= 1 ? (
            ""
          ) : (
            <div
              className="b-refs-buttons"
              style={
                deflang != "en"
                  ? {
                      width: "50%",
                      left: "0",
                      textAlign: "right",
                      direction: "rtl",
                    }
                  : { disolay: "inline-block" }
              }
            >
              {activeIndex == 0 ? (
                ""
              ) : (
                <button onClick={slidePrev} className={activeIndex}>
                  <IoIosArrowBack
                    style={
                      deflang != "en"
                        ? {
                            transform: "rotate(180deg)",
                          }
                        : { direction: "ltr" }
                    }
                  />{" "}
                  {deflang == "en"
                    ? i18n.store.data.en.translation.previous
                    : deflang == "ar"
                    ? i18n.store.data.ar.translation.previous
                    : i18n.store.data.he.translation.previous}
                </button>
              )}
              {activeIndex == productsArray.length - 1 ? (
                ""
              ) : (
                <button onClick={slideNext} className={activeIndex}>
                  {deflang == "en"
                    ? i18n.store.data.en.translation.next
                    : deflang == "ar"
                    ? i18n.store.data.ar.translation.next
                    : i18n.store.data.he.translation.next}
                  <IoIosArrowForward
                    style={
                      deflang != "en"
                        ? {
                            transform: "rotate(180deg)",
                          }
                        : { direction: "ltr" }
                    }
                  />
                </button>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>

      <div
        className="name"
        style={
          deflang != "en"
            ? {
                textAlign: "right",
              }
            : { textAlign: "left" }
        }
      >
        <h5>{name}</h5>
      </div>
      <div className="short-desc">
        {ReactHtmlParser(ReactHtmlParser(trimString(desc, 300)))}
      </div>
    </div>
  );
};

export default card;
