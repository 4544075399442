/* eslint-disable no-undef */
import { GET_SEARCRH, SEARCH_ERROR, RESET_SEARCH } from "../types";
import axios from "axios";

const apiUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PRO_HOMEPAGE
    : process.env.REACT_APP_DEV_HOMEPAGE;

export const getSearch = (number) => async (dispatch) => {
  try {
    // eslint-disable-next-line no-undef
    const res = await axios.get(`${apiUrl}api/search/?number=${number}`);
    dispatch({
      type: GET_SEARCRH,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: SEARCH_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const reset = () => async (dispatch) => {
  dispatch({
    type: RESET_SEARCH,
  });
};
