import { GET_SEARCRH, SEARCH_ERROR, RESET_SEARCH } from "../types";

const initialState = {
  search: {},
  error: "",
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SEARCRH:
      return {
        ...state,
        search: action.payload,
        loading: false,
      };
    case SEARCH_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: true,
      };
    case RESET_SEARCH:
      return {
        ...state,
        loading: true,
      };

    default:
      return state;
  }
}
