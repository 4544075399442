/* eslint-disable no-unused-vars */
import React from "react";
import "./App.scss";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "./store/actions/categoriesAction";
import { getUserIP, getBannedUserIP } from "./store/actions/ipAction";
import { Container, Alert, Row } from "react-bootstrap";
import Cards from "./components/cards/categories";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

function App() {
  const deflang = localStorage.getItem("def_lang")
    ? JSON.parse(localStorage.getItem("def_lang"))
    : localStorage.getItem("i18nextLng");
  const history = useHistory();
  // window.location.reload(false);
  const dispatch = useDispatch();
  const categoriesList = useSelector((state) => state.categories);
  const userIPAdd = useSelector((state) => state.userIP);

  const { userIP, bannedIP } = userIPAdd;

  // console.log(userIPAdd);

  const { i18n } = useTranslation();

  const { loading, error, categories } = categoriesList;

  window.onload = function () {
    if (!window.location.hash) {
      window.location = window.location + "#language";
      window.location.reload();
    }
  };

  const banthisIP = JSON.parse(localStorage.getItem("userIP"));

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getUserIP());
    dispatch(getBannedUserIP(banthisIP));
  }, [dispatch]);

  if (bannedIP == "true") {
    return (
      <>
        <div className="iplogo">
          <img src={JSON.parse(localStorage.getItem("logo"))} alt="" />
        </div>
      </>
    );
  }

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {deflang == "en"
            ? i18n.store.data.en.translation.bakecake
            : deflang == "ar"
            ? i18n.store.data.ar.translation.bakecake
            : i18n.store.data.he.translation.bakecake}
        </title>
      </Helmet>
      <section>
        <Container>
          <div id="catcards">
            <Cards categories={categories} loading={loading} />
          </div>
        </Container>
      </section>
    </div>
  );
}

export default App;
