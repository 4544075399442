import React from "react";
import { useLocation } from "react-router-dom";
import { Row, Container, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const singleProduct = () => {
  const deflang = localStorage.getItem("def_lang")
    ? JSON.parse(localStorage.getItem("def_lang"))
    : localStorage.getItem("i18nextLng");
  const { i18n } = useTranslation();
  //const { id } = useParams();
  const state = useLocation();

  const { state: product } = state;

  console.log(product);

  return (
    <div>
      <Container id="product-single">
        <div
          className="breadcrumbs"
          style={
            deflang != "en"
              ? { direction: "rtl", display: "flex" }
              : { direction: "ltr" }
          }
        >
          <Link to="/">
            {deflang == "en"
              ? i18n.store.data.en.translation.home
              : deflang == "ar"
              ? i18n.store.data.ar.translation.home
              : i18n.store.data.he.translation.home}
          </Link>
          <span>/</span>{" "}
          <span>
            {deflang == "en"
              ? i18n.store.data.en.translation.searchText
              : deflang == "ar"
              ? i18n.store.data.ar.translation.searchText
              : i18n.store.data.he.translation.searchText}
          </span>
        </div>

        <Row
          style={deflang != "en" ? { direction: "rtl" } : { direction: "ltr" }}
        >
          <Col md={6} className="mx-auto">
            <div id="procard" style={{ cursor: "auto" }}>
              <div className="pro-image">
                <img
                  src={`${product.image_slug}`}
                  alt=""
                  style={{ height: "unset" }}
                />
              </div>
              <div
                className="name"
                style={{ padding: "5px", textAlign: "center" }}
              >
                <h5>{product.product_name}</h5>
                <p>{product.product_number}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default singleProduct;
