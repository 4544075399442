export const GET_CATEGORIES = "GET_CATEGORIES";
export const CATEGORIES_ERROR = "CATEGORIES_ERROR";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const PRODUCTS_ERROR = "PRODUCTS_ERROR";
export const GET_SETTINGS = "GET_SETTINGS";
export const SETTINGS_ERROR = "SETTINGS_ERROR";
export const GET_PHONE = "GET_PHONE";
export const PHONE_ERROR = "PHONE_ERROR";
export const GET_CATEGORIES_BY_ID = "GET_CATEGORIES_BY_ID";
export const CATEGORIES_BY_ID_ERROR = "CATEGORIES_BY_ID_ERROR";
export const GET_CATEGORIES_NAME_BY_ID = "CATEGORIES_BY_ID_ERROR";
export const CATEGORIES_NAME_BY_ID_ERROR = "CATEGORIES_BY_ID_ERROR";
export const GET_LANGUAGE = "GET_LANGUAGE";
export const LANGUAGE_ERROR = "LANGUAGE_ERROR";
export const DEFAULT_LANG = "DEFAULT_LANG";
export const SHOW_LANGUAGE = "SHOW_LANGUAGE";
export const SHOW_LANGUAGE_ERROR = "SHOW_LANGUAGE_ERROR";
export const GET_SEARCRH = "GET_SEARCRH";
export const SEARCH_ERROR = "SEARCH_ERROR";
export const RESET_SEARCH = "RESET_SEARCH";
export const GET_USER_IP = "GET_USER_IP";
export const USER_IP_ERROR = "USER_IP_ERROR";
export const GET_BANNED_USER_IP = "GET_BANNED_USER_IP";
export const ERROR_BANNED_USER_IP = "ERROR_BANNED_USER_IP";
