import React from "react";
import Skeleton from "react-loading-skeleton";
import { Row, Col } from "react-bootstrap";
import "./style.scss";
import { Link } from "react-router-dom";

const cards = ({ categories, loading }) => {
  //const lang = localStorage.getItem("i18nextLng");

  const deflang = localStorage.getItem("def_lang")
    ? JSON.parse(localStorage.getItem("def_lang"))
    : localStorage.getItem("i18nextLng");
  var i;
  var skeleton = [];
  for (i = 0; i < 8; i++) {
    skeleton.push(
      <Col md={3}>
        <div className="catcard">
          <div className="catimag">
            <Skeleton height={250} count={1} />
          </div>
          <div className="catname">
            <Skeleton count={1} height={30} />
          </div>
        </div>
      </Col>
    );
  }

  if (loading) {
    return <Row>{skeleton}</Row>;
  }
  return (
    <Row style={deflang != "en" ? { direction: "rtl" } : { direction: "ltr" }}>
      {categories.map((cat) =>
        cat.parent_id == 0 ? (
          <Col md={3} key={cat.id}>
            {cat.subcategory.length > 0 ? (
              <Link to={`/sub-category/${cat.id}`}>
                <div className="catcard">
                  <div className="catimag">
                    <img
                      // eslint-disable-next-line no-undef
                      src={`${cat.image_slug}`}
                      alt=""
                    />
                  </div>
                  <div className="catname">
                    <h6>{cat.name}</h6>
                  </div>
                </div>
              </Link>
            ) : (
              <Link to={`/category/${cat.id}`}>
                <div className="catcard">
                  <div className="catimag">
                    <img
                      // eslint-disable-next-line no-undef
                      src={`${cat.image_slug}`}
                      alt=""
                    />
                  </div>
                  <div className="catname">
                    <h6>{cat.name}</h6>
                  </div>
                </div>
              </Link>
            )}
          </Col>
        ) : (
          ""
        )
      )}
    </Row>
  );
};

export default cards;
