/* eslint-disable no-undef */
import {
  GET_CATEGORIES,
  CATEGORIES_ERROR,
  GET_CATEGORIES_BY_ID,
  CATEGORIES_BY_ID_ERROR,
  GET_CATEGORIES_NAME_BY_ID,
  CATEGORIES_NAME_BY_ID_ERROR,
} from "../types";
import axios from "axios";

const apiUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PRO_HOMEPAGE
    : process.env.REACT_APP_DEV_HOMEPAGE;

export const getCategories = () => async (dispatch) => {
  try {
    // eslint-disable-next-line no-undef
    const res = await axios.get(`${apiUrl}api/categories/`);
    dispatch({
      type: GET_CATEGORIES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: CATEGORIES_ERROR,
      payload: error,
    });
  }
};
export const getCategoriesById = (id) => async (dispatch) => {
  try {
    // eslint-disable-next-line no-undef
    const res = await axios.get(`${apiUrl}api/categories/?id=${id}`);
    dispatch({
      type: GET_CATEGORIES_BY_ID,
      payload: res.data,
    });
    dispatch({
      type: GET_CATEGORIES_NAME_BY_ID,
      payload: res.data.name,
    });
  } catch (error) {
    dispatch({
      type: CATEGORIES_BY_ID_ERROR,
      payload: error,
    });
  }
};
export const getCategoriesNameById = (id) => async (dispatch) => {
  try {
    // eslint-disable-next-line no-undef
    const res = await axios.get(`${apiUrl}api/categories/?id=${id}`);

    // const {
    //   categoryName: { parent_id },
    // } = getState();

    dispatch({
      type: GET_CATEGORIES_NAME_BY_ID,
      payload: res.data.name,
    });
  } catch (error) {
    dispatch({
      type: CATEGORIES_NAME_BY_ID_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
