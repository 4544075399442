/* eslint-disable no-undef */
import {
  GET_USER_IP,
  USER_IP_ERROR,
  GET_BANNED_USER_IP,
  ERROR_BANNED_USER_IP,
} from "../types";
import axios from "axios";

const apiUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PRO_HOMEPAGE
    : process.env.REACT_APP_DEV_HOMEPAGE;

export const getUserIP = () => async (dispatch) => {
  try {
    // eslint-disable-next-line no-undef
    const res = await axios.get(`https://geolocation-db.com/json/`);
    dispatch({
      type: GET_USER_IP,
      payload: res.data.IPv4,
    });

    localStorage.setItem("userIP", JSON.stringify(res.data.IPv4));
  } catch (error) {
    dispatch({
      type: USER_IP_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getBannedUserIP = (ip) => async (dispatch) => {
  try {
    // eslint-disable-next-line no-undef
    const res = await axios.get(`${apiUrl}api/ip/?ip=${ip}`);
    dispatch({
      type: GET_BANNED_USER_IP,
      payload: res.data,
    });
    localStorage.setItem("userIPCondition", JSON.stringify(res.data.message));
  } catch (error) {
    dispatch({
      type: ERROR_BANNED_USER_IP,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
