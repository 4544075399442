/* eslint-disable no-undef */
import { GET_PRODUCTS, PRODUCTS_ERROR } from "../types";
import axios from "axios";

const apiUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_PRO_HOMEPAGE
    : process.env.REACT_APP_DEV_HOMEPAGE;

export const getProducts = (catid) => async (dispatch) => {
  try {
    // eslint-disable-next-line no-undef
    const res = await axios.get(`${apiUrl}api/products/?cat_id=${catid}`);
    dispatch({
      type: GET_PRODUCTS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: PRODUCTS_ERROR,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
