/* eslint-disable no-unused-vars */
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../store/actions/productAction";
import { getCategoriesById } from "../../store/actions/categoriesAction";
import { useParams } from "react-router-dom";
import Card from "../products/card";
import { Col, Row, Container, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";

const category = () => {
  //const lang = localStorage.getItem("i18nextLng");
  const deflang = localStorage.getItem("def_lang")
    ? JSON.parse(localStorage.getItem("def_lang"))
    : localStorage.getItem("i18nextLng");
  var i;
  var skeleton = [];
  for (i = 0; i < 8; i++) {
    skeleton.push(
      <Col md={3}>
        <div className="catcard">
          <div className="catimag">
            <Skeleton height={250} count={1} />
          </div>
          <div className="catname">
            <Skeleton count={1} height={30} />
          </div>
        </div>
      </Col>
    );
  }
  const { i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const productsList = useSelector((state) => state.products);
  const catName = useSelector((state) => state.categories);
  const { loading, products, error } = productsList;

  const userIPAdd = useSelector((state) => state.userIP);

  const { bannedIP } = userIPAdd;

  const {
    categoryName: { name, parent_id, parent_cat_name },
  } = catName;

  useEffect(() => {
    dispatch(getProducts(id));
    dispatch(getCategoriesById(id));
  }, [dispatch]);
  if (bannedIP == "true") {
    return (
      <>
        <Alert variant="danger">You are Banned from Accessing this site</Alert>
      </>
    );
  } else if (loading) {
    return (
      <>
        <Row>{skeleton}</Row>
      </>
    );
  } else if (error) {
    return (
      <>
        <Alert variant="danger">{error}</Alert>
      </>
    );
  }
  return (
    <>
      <Container>
        <div
          className="breadcrumbs"
          style={
            deflang != "en"
              ? { direction: "rtl", display: "flex" }
              : { direction: "ltr" }
          }
        >
          <Link to="/">
            {deflang == "en"
              ? i18n.store.data.en.translation.home
              : deflang == "ar"
              ? i18n.store.data.ar.translation.home
              : i18n.store.data.he.translation.home}
          </Link>
          {parent_id >= 1 && (
            <>
              <span> / </span>
              <span>
                <Link to={`/sub-category/${parent_id}`}>{parent_cat_name}</Link>
              </span>
            </>
          )}{" "}
          <span>/</span> <span>{name}</span>
        </div>

        <Row
          style={deflang != "en" ? { direction: "rtl" } : { direction: "ltr" }}
        >
          {products.map((product, i) => (
            <Col md={3} key={product.id}>
              <Card
                id={product.id}
                index={i}
                name={product.product_name}
                desc={product.description}
                image={product.image_slug}
                price={product.price}
                productsArray={products}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default category;
