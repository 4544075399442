/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "../search/search";
import {
  getSettingsByName,
  getPhone,
  getLanguage,
  showLanguage,
} from "../../store/actions/settingsAction";
import { getDefLang } from "../../store/actions/langAction";
import { Dropdown, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import "./header.scss";
import { useTranslation } from "react-i18next";
import { getUserIP, getBannedUserIP } from "../../store/actions/ipAction";

const header = () => {
  const deflang = localStorage.getItem("def_lang")
    ? JSON.parse(localStorage.getItem("def_lang"))
    : localStorage.getItem("i18nextLng");

  const dispatch = useDispatch();
  const settingsList = useSelector((state) => state.settings);
  const userIPAdd = useSelector((state) => state.userIP);

  const { bannedIP } = userIPAdd;
  const {
    settings: { setting_value: logo },
  } = settingsList;
  const { i18n } = useTranslation();
  const changeLanguageHandler = (e) => {
    localStorage.removeItem("def_lang");
    const value = e.target.value;
    dispatch(getDefLang(value));
    i18n.changeLanguage(value);
  };

  const {
    phone: { setting_value: phone },
  } = settingsList;

  const {
    showLang: { setting_value: showLang },
  } = settingsList;

  const { langSetting } = settingsList;

  const backendUrl =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_PRO_HOMEPAGE
      : process.env.REACT_APP_DEV_HOMEPAGE;

  // const def_lang =
  //   default_language == 1 ? "en" : default_language == 2 ? "ar" : "he";

  const banthisIP = JSON.parse(localStorage.getItem("userIP"));

  useEffect(() => {
    dispatch(getSettingsByName("logo"));
    dispatch(getPhone("business_tel"));
    dispatch(getLanguage());
    dispatch(showLanguage("change_language"));
    dispatch(getUserIP());
    dispatch(getBannedUserIP(banthisIP));
    localStorage.setItem("def_lang", JSON.stringify(langSetting));

    //dispatch(getDefLang(def_lang));
  }, [dispatch, langSetting]);
  if (bannedIP == "true") {
    return <></>;
  }
  return (
    <Container>
      <div
        className="headBg"
        style={deflang != "en" ? { direction: "rtl" } : { direction: "ltr" }}
      >
        <div className="logo">
          <Link to="/">
            {" "}
            <img src={`${backendUrl}${logo}`} alt="" />
          </Link>
        </div>
        <div className="mobLang">
          <div
            className="mobile"
            style={
              deflang != "en" ? { paddingLeft: "20px" } : { paddingLeft: "" }
            }
          >
            <FaWhatsapp /> <span>{phone}</span>
          </div>
          <div
            className="language"
            style={showLang == "1" ? { display: "none" } : { display: "block" }}
          >
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                {deflang == "en"
                  ? "English"
                  : deflang == "he"
                  ? "Hebrew"
                  : "Arabic"}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {deflang == "en" ? (
                  ""
                ) : (
                  <Dropdown.Item>
                    <button
                      style={{ background: "transparent" }}
                      value="en"
                      onClick={changeLanguageHandler}
                    >
                      English
                    </button>
                  </Dropdown.Item>
                )}

                {deflang == "ar" ? (
                  ""
                ) : (
                  <Dropdown.Item>
                    <button
                      style={{ background: "transparent" }}
                      value="ar"
                      onClick={changeLanguageHandler}
                    >
                      Arabic
                    </button>
                  </Dropdown.Item>
                )}
                {deflang == "he" ? (
                  ""
                ) : (
                  <Dropdown.Item>
                    <button
                      style={{ background: "transparent" }}
                      value="he"
                      onClick={changeLanguageHandler}
                    >
                      Hebrew
                    </button>
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
      <SearchBar />
    </Container>
  );
};

export default header;
