import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../store/actions/categoriesAction";
import { useParams, Link } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { getCategoriesById } from "../../store/actions/categoriesAction";
import "./style.scss";
import { useTranslation } from "react-i18next";

const subcategory = () => {
  const deflang = localStorage.getItem("def_lang")
    ? JSON.parse(localStorage.getItem("def_lang"))
    : localStorage.getItem("i18nextLng");
  const { i18n } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const categoriesList = useSelector((state) => state.categories);
  const catName = useSelector((state) => state.categories);
  const { categories } = categoriesList;
  const {
    categoryName: { name },
  } = catName;

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getCategoriesById(id));
  }, [dispatch]);
  return (
    <section>
      <Container>
        <div
          className="breadcrumbs"
          style={
            deflang != "en"
              ? { direction: "rtl", display: "flex" }
              : { direction: "ltr" }
          }
        >
          <Link to="/">
            {deflang == "en"
              ? i18n.store.data.en.translation.home
              : deflang == "ar"
              ? i18n.store.data.ar.translation.home
              : i18n.store.data.he.translation.home}
          </Link>{" "}
          <span>/</span> <span>{name}</span>
        </div>
        <div id="catcards">
          <Row>
            {categories.map((cat) =>
              cat.id == id
                ? cat.subcategory.map((sc) => (
                    <Col md={3} key={sc.id}>
                      <Link to={`/category/${sc.id}`}>
                        <div className="catcard">
                          <div className="catimag">
                            <img
                              // eslint-disable-next-line no-undef
                              src={`${sc.image_slug}`}
                              alt=""
                            />
                          </div>
                          <div className="catname">
                            <h6>{sc.name}</h6>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  ))
                : ""
            )}
          </Row>
        </div>
      </Container>
    </section>
  );
};

export default subcategory;
