import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "./locales/en/translation.json";
import translationAR from "./locales/ar/translation.json";
import translationHE from "./locales/he/translation.json";
// the translations
// (tip move them in a JSON file and import them)

const resources = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
  he: {
    translation: translationHE,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng:
      localStorage.getItem("def_lang") ||
      localStorage.getItem("i18nextLng") ||
      "ar",

    keySeparator: false,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
